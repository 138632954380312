//imports
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto:300');

$font-size-base: 17px;
$text-color: lighten(#333, 15%);

$site-logo: url("../../assets/img/logo.png");

// colors
$dark-brown: #595241;
$light-brown: rgb(226,231,215);
$white: #ffffff;
$bb-blue: rgb(0,110,200);
$deep-red: rgb(249, 143, 2);
$trans-light-brown: rgba(184,174,156,0.5);
$trans-white: rgba(255,255,255,0.5);

//font fams
$header-font: 'Roboto', sans-serif;
$text-font: 'Roboto Condensed', sans-serif;
