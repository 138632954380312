h1, h2, h3, h4 {
	font-family: $header-font;
	margin-top: 1em;
}

p, small, blockquote, ul > li, ol > li, table > * {
	font-family: $text-font;
	line-height: 1.625em;
}

h3 {
	font-style: italic;
}

blockquote {
	padding-left: 30px;
	border-left: 8px solid $bb-blue;
}

a {
	color: $bb-blue;
	text-decoration: none;
	transition: 1s all ease-in-out;
	&:hover, &:focus {
		color: lighten($bb-blue, 15%);
		transition: 1s all ease-in-out;
		text-decoration: none;
	}
}

body {
	background-color:$white;
}

::selection {
	background-color: $bb-blue;
	color: $white;
}

.btn-default {
	background-color: $bb-blue;
	border-radius: 0;
	color: $white;
	font-family: $header-font;
	font-size: 22px;
	transition: 0.25s all ease-in-out;
	margin-top: 13px;
	&:hover, &:focus {
		color: $white;
		background-color: darken($bb-blue, 25%);
		transition: 0.5s all ease-in-out;
	}
}




nav {
	background-color: rgba(184, 174, 156, .6);
	&.navbar {
		margin-bottom:0;
		border-radius: 0;
	}
	.navbar-header {
		min-height: 80px;
		.navbar-brand {
			color: $dark-brown;
			img {
				max-width: 150px;
			}
		}
		.navbar-toggle {
			margin-top: 13px;
			.glyphicon-earphone {
				font-size: 35px;
				color: $white;
			}
			.icon-bar {
				transition: all 0.2s;
				background-color:white;
				height: 6px;
				width: 33px!important;
				margin-top: 5px;
			}
			.top-bar {
				transform: rotate(45deg);
				transform-origin: 14% 80%;
			}
			.middle-bar {
				opacity: 0;
			}
			.bottom-bar {
				transform: rotate(-45deg);
				transform-origin: 0% 0%;
			}
			&.collapsed {
				.top-bar {
					transform: rotate(0);
				}
				.middle-bar {
					opacity: 1;
				}
				.bottom-bar {
					transform: rotate(0);
				}
			}
		}
	}
	.navbar-nav {
		margin-top:30px;
		& > li {
			& > a {
				font-size: 18px;
				color: $white;
				transition: 0.25s all ease-in-out;
				&:hover, &:focus {
					background-color: rgba(0,110,200, .5);
				}
				transition: 0.5s all ease-in-out;
			}
		}
	}
}

.brown-bg {
	background-color: lighten($dark-brown, 5%);
}

.phone-bar {
	text-align: right;
	background-color: rgba(184, 174, 156, .5);
	small {
		font-size: 0.75em;
		.glyphicon-asterisk {
			font-size: 0.65em;
			margin-left:5px;
			margin-right:5px;
		}
	}
}
.splash-container {
	text-align:center;
	#slideshow {
		#carousel {
			.carousel-inner {
				& > .active {
					img {
						// margin-left:auto;
						// margin-right:auto;
					}
				}
				& > .item {
					img {
						width:100%;
						opacity: 0.55;
						filter: alpha(opacity=55); /* For IE8 and earlier */
					}
					.carousel-caption {
						margin-top: 15px;
						color: $white;
					}
				}
			}
		}
	}
}

.texture-bg {
	//background-image: url('../../assets/img/concrete_seamless.png');
	.white-overlay{
		background-color: $trans-white;
		.home-info-container {
			.home-info-box {
				padding-top: 20px;
				padding-bottom: 30px;
			}
		}
		.interior-container {
			.filter-bar {
				margin-bottom: 20px;
			}
			.gallery-img {
				height: 250px;
				overflow: hidden;
				margin-bottom: 10px;
				& > .img-responsive {
					width: 100%;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	footer {
		background-color: $trans-light-brown;
		min-height: 200px;
		text-align: center;
		.container {
			padding: 40px;
			.footer-location {
				padding-top: 20px;
				padding-bottom:20px;
			}
		}
	}
}

.btn-default {
	background-color: $bb-blue;
}
