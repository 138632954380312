@media (max-width: 600px) {

}

@media (min-width: 768px) {
	nav .navbar-nav {
		margin-left: 50px;
	}
	.navbar {
		min-height: 100px;
		.container {
			.navbar-header {
				.navbar-brand {
					position: absolute;
					z-index: 1;
					padding: 0;
					margin: 45px 0 10px;
					img {
						width: 100%;
						max-width: 170px;
						margin-left: -10px;
					}
				}
			}
			.navbar-collapse {
				margin-top: 21px;
				margin-left: 100px;
				.navbar-nav > li > a{
					padding-left: 30px;
					padding-right:30px;
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.interior-container {
		min-height: 700px;
		& > h1 {
			margin-top: 1.35em;
		}
	}
	.footer-location {
		padding-top: 0;
		padding-bottom:0;
		&:nth-child(1){
			text-align: left;
		}
		&:nth-child(2){
			text-align: right;
		}
		&:nth-child(3){
			text-align: center;
		}
	}
}

@media(min-width: 1025px) {
	.home-info-container {
		.home-info-box {
			min-height: 320px;
			margin-top: 40px;
		}
	}
}
